export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../../src/routes/__error.svelte"),
	() => import("../../src/routes/a/[application]/__layout.svelte"),
	() => import("../../src/routes/a/[application]/[profile].svelte"),
	() => import("../../src/routes/i/[email]/already-subscribed.svelte"),
	() => import("../../src/routes/i/[email]/declined-before.svelte"),
	() => import("../../src/routes/i/[email]/declined.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/s/[id]/index.svelte"),
	() => import("../../src/routes/s/[id]/success.svelte")
];

export const dictionary = {
	"": [[0, 7], [1]],
	"s/[id]": [[0, 8], [1]],
	"i/[email]/already-subscribed": [[0, 4], [1]],
	"i/[email]/declined-before": [[0, 5], [1]],
	"i/[email]/declined": [[0, 6], [1]],
	"s/[id]/success": [[0, 9], [1]],
	"a/[application]/[profile]": [[0, 2, 3], [1]]
};